import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import './NotFound.scss';
import Arrow2 from '../../Images/Arrow2.webp'
import error404 from '../../Images/error404.png'

// import { useLocation } from "react-router-dom";
import NavigatorPath from "../NavigatorPath/NavigatorPath"
import { useEffect } from 'react';


export default function NotFound({ }) {
    const location = useLocation()


    useEffect(() => {

    }, [location.pathname]);

    return (
        <div className="NotFound">
            <div className="NotFoundHeader">
                <img src={error404} alt="" />
                <h1>We could not find the page you were looking for</h1>
                <h2>We don't like to come empty-handed, so here are some useful links:</h2>
            </div>
            <div className="NotFoundMenu">
                <a href={`${"/"}`}>Home Page</a>
                <a href={`/${"apply"}`}>Visit our business funding application page</a>
                <a href={`/${"blog"}`}>Explore The Calabasas Chimney Sweep  Blog For Insightful Articles</a>
                <a href={`/${"sitemaps"}`}>Site Map</a>
            </div>
            <div className="NotFoundNavigatorPath">
                <div className="NavigatorPathBox" >
                    <a href={`/`}>Calabasas Chimney Sweep</a>
                    <img src={Arrow2} alt="" />
                </div>
                <div className="NavigatorPathBox" >
                    <a href={``} style={{"textDecoration":"unset"}}>404</a>
                </div>
            </div>
        </div>
    )
}
